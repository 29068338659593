<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    :alternative-selects="['uri']"
    entity-type="source"
    locale-section="pages.sources"
    field-section="sources"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "SourceDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: title
        title
        author
        year
        abbreviation
        uri {
            id
        }
      `,
      querySelects: /* GraphQL */ `
        uri: uris {
            id
            uri
        },
        uri__alternative: findUnusedURIs {
            id
            uri
        }
      `,
      inputAttributes: {
        title: { required: true }
      }
    };
  }
};
</script>
